import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  loadThreatsDetected,
  loadLoginPagesScannedEvents,
  loadTopThreats,
  loadTopVulnerable,
  setChartFilterInStore,
} from '../store/entities/events'
import { produce } from 'immer'
import './incidentCharts.scss'
import { globalViewText, isGlobalView } from '../utilities/config'
import LoaderWrapper from './common/LoaderWrapper/LoaderWrapper'
import ChartBarBuild from './charts/ChartBarBuild'
import ChartDoubleBarBuild from './charts/ChartDoubleBarBuild'
import ChartHorizontalBarBuild from './charts/ChartHorizontalBarBuild'
import { eventTypes } from '../utilities/eventTypes'

const IncidentCharts = (props) => {
  const [showLogin, setShowLogin] = useState(false)
  const [showThreatDetected, setShowThreatDetected] = useState(false)
  const [showTopThreats, setShowTopThreats] = useState(false)
  const [showTopVulnerable, setShowTopVulnerable] = useState(false)
  const [threatCount, setThreatCount] = useState(0)

  let stateFilters = props.filters

  useEffect(() => {
    setShowLogin(false)
    setShowThreatDetected(false)
    setShowTopThreats(false)
    setShowTopVulnerable(false)
    removeFilter('pageSize')
    removeFilter('brand')
    let triagedEvent = null
    if (stateFilters.hasOwnProperty('triagedEvent')) {
      triagedEvent = stateFilters['triagedEvent']
    }
    loadLoginData()
    loadThreatDetected(triagedEvent)
    loadTopThreats(triagedEvent)
    loadTopVulnerable(triagedEvent)
  }, [props.selectedCustomer])

  useEffect(() => {
    setShowLogin(false)
    setShowThreatDetected(false)
    setShowTopThreats(false)
    setShowTopVulnerable(false)
  }, [props.filters])

  useEffect(() => {
    setShowLogin(true)
  }, [props.loginList])

  useEffect(() => {
    setShowThreatDetected(true)    
    if(stateFilters.hasOwnProperty('triagedEvent')) {
      let recordCount = props.threatDetected.records.reduce((acc, curr) => acc + curr[stateFilters['triagedEvent'] === '1' ? 'verified' : 'suspicious'], 0)
      setThreatCount(recordCount)
    } else {
      setThreatCount(props.threatDetected.totalRecordCount)
    }    
  }, [props.threatDetected])

  useEffect(() => {
    setShowTopThreats(true)
  }, [props.topthreats])

  useEffect(() => {
    setShowTopVulnerable(true)
  }, [props.topvulnerable])

  const loadLoginData = () => {
    let objFilter = {}
    objFilter['startTime'] = stateFilters.startTime
    objFilter['endTime'] = stateFilters.endTime
    objFilter['requireTotalRecordsCount'] = 1
    objFilter['numberOfBuckets'] = 9
    objFilter['cKey'] = props.selectedCustomer.key;
    objFilter['r'] = isGlobalView(props.selectedCustomer && typeof props.selectedCustomer.organizationName === "string" ? props.selectedCustomer.organizationName : props.match.params.id)
      ? 'M'
      : 'C'
    setFilters(objFilter)
    // removeFilter('triagedEvent')
    props.loadLoginPagesScannedEvents(objFilter, props.selectedCustomer.id, props.selectedCustomer.defaultCustomerId)
  }

  const loadThreatDetected = (triagedEvent) => {
    let objFilter = {}
    removeFilter('removeThreatUrl')
    removeFilter('requireTotalRecordsCount')
    removeFilter('includeFalsePositive')
    objFilter['type'] = [eventTypes.incident]
    if (triagedEvent !== null) {
      objFilter['triagedEvent'] = triagedEvent
    }
    objFilter['chartType'] = 'threatDetected'
    objFilter['numberOfBuckets'] = 9
    objFilter['r'] = isGlobalView(props.selectedCustomer.organizationName)
      ? 'M'
      : 'C'
    objFilter['offset'] = 0
    objFilter['cKey'] = props.selectedCustomer.key;
    setFilters(objFilter)
    props.loadThreatsDetected(
      stateFilters,
      props.selectedCustomer.id,
      props.selectedCustomer.defaultCustomerId
    )
    removeFilter('numberOfBuckets')
    removeFilter('chartType')
  }

  const loadTopThreats = (triagedEvent) => {
    let objFilter = {}
    removeFilter('removeThreatUrl')
    removeFilter('requireTotalRecordsCount')
    removeFilter('includeFalsePositive')
    objFilter['type'] = [eventTypes.incident]
    objFilter['chartType'] = 'topthreats'
    if (triagedEvent !== null) {
      objFilter['triagedEvent'] = triagedEvent
    }
    objFilter['r'] = isGlobalView(props.selectedCustomer.organizationName)
      ? 'M'
      : 'C'
    objFilter['offset'] = 0
    objFilter['fetchOnlyRecords'] = 13
    objFilter['cKey'] = props.selectedCustomer.key;
    setFilters(objFilter)
    props.loadTopThreats(
      stateFilters,
      props.selectedCustomer.id,
      props.selectedCustomer.defaultCustomerId
    )
    removeFilter('chartType')
  }

  const loadTopVulnerable = (triagedEvent) => {
    let objFilter = {}
    removeFilter('removeThreatUrl')
    removeFilter('requireTotalRecordsCount')
    removeFilter('includeFalsePositive')
    objFilter['type'] = [eventTypes.incident]
    objFilter['chartType'] = 'topvulnerable'
    if (triagedEvent !== null) {
      objFilter['triagedEvent'] = triagedEvent
    }
    objFilter['r'] = isGlobalView(props.selectedCustomer.organizationName)
      ? 'M'
      : 'C'
    objFilter['offset'] = 0
    objFilter['fetchOnlyRecords'] = 13
    objFilter['cKey'] = props.selectedCustomer.key;
    setFilters(objFilter)
    props.loadTopVulnerable(
      stateFilters,
      props.selectedCustomer.id,
      props.selectedCustomer.defaultCustomerId
    )
    removeFilter('chartType')
  }

  const removeFilter = (key) => {
    let filters = produce(stateFilters, (draftState) => {
      delete draftState[key]
    })
    stateFilters = filters
    props.setChartFilterInStore(stateFilters)
  }

  const setFilters = (changedValues) => {
    let filters = produce(stateFilters, (draftState) => {
      Object.keys(changedValues).map((property) => {
        draftState[property] = changedValues[property]
      })
    })
    stateFilters = filters
    props.setChartFilterInStore(stateFilters)
  }

  return (
      <>
      
        <div className="chartContainer mt-1" style={{minHeight: '800px'}}>{
            props.loading ?
              <LoaderWrapper loading={props.loading} waitForData={false}></LoaderWrapper>
            :
            <>
              <div className="chartRow">
                <div className="outerBox">
                  <span className="headDetails">
                    Login Pages Scanned:{' '}
                    <b>
                      {props.loginTotalSize &&
                        props.loginTotalSize.toLocaleString()}
                    </b>
                  </span>
                  <div className="innerBox incidentInnerBox">
                    {props.loginList ? (
                      props.loginList.length > 0 ? (
                        <ChartBarBuild
                          loginList={
                            props.loginList && showLogin ? props.loginList : null
                          }
                        />
                      ) : (
                        <div className="notFound headDetails">
                          No Login Pages Scanned
                        </div>
                      )
                    ) : (
                      <ChartBarBuild loginList={null} />
                    )}
                  </div>
                </div>
                <div className="outerBox rightChart">
                  <span className="headDetails">
                    Threats detected:{' '}
                    <b>{props.threatDetected.totalRecordCount ? threatCount : 0}</b>
                  </span>
                  <div className="innerBox incidentInnerBox">
                  <LoaderWrapper 
                    loading={props.threatDetectedLoading} data={props.threatDetected.records} waitForData={true}
                    styles={{ minHeight: '324px' }}  isChart={true} placeholderText={"No Threats Detected"}
                  >
                      <ChartDoubleBarBuild
                        records={
                          props.threatDetected.records && showThreatDetected
                            ? props.threatDetected.records
                            : null
                        }
                        triagedEvent={
                          props.filters.triagedEvent != undefined
                            ? props.filters.triagedEvent
                            : null
                        }
                      />
                      <div className="legendPosition">
                        <span className="legendHead">
                          <b>Type</b>
                        </span>
                        <div className="symbol suspicious"></div>
                        <span className="legendText">Suspicious</span>
                        <div className="symbol verified"></div>
                        <span className="legendText">Verified Phish</span>
                      </div>
                    </LoaderWrapper>
                  </div>
                </div>
            </div>

            <div className="chartRow">
              <div className="outerBox">
                <span className="headDetails">Top Threats</span>
                <div className="innerBox incidentInnerBox">
                <LoaderWrapper 
                  loading={props.topthreatsLoading} data={props.topthreats.records} waitForData={true}
                  styles={{ minHeight: '324px' }}  isChart={true} placeholderText={"No Threats Detected"}
                >
                    <ChartHorizontalBarBuild
                      records={
                        props.topthreats.records && showTopThreats
                          ? props.topthreats.records
                          : null
                      }
                      eventType="url"
                    />
                </LoaderWrapper>
                </div>
              </div>

              <div className="outerBox rightChart">
                <span className="headDetails">Top Vulnerable Users</span>
                <div className="innerBox incidentInnerBox">
                <LoaderWrapper 
                  loading={props.topvulnerableLoading} data={props.topvulnerable.records} waitForData={true}
                  styles={{ minHeight: '324px' }}  isChart={true} placeholderText={"No Threats Detected"}
                >
                    <ChartHorizontalBarBuild
                      records={
                        props.topvulnerable.records && showTopVulnerable
                          ? props.topvulnerable.records
                          : null
                      }
                      eventType="email"
                    />
                  </LoaderWrapper>
                </div>
              </div>
            </div>
          </>
          }
          
        </div>
    </>       
  )
}

const mapStateToProps = (state) => ({
  filters: state.entities.events.chart.filters,
  selectedCustomer: state.entities.customers.selected,
  loginList: state.entities.events.loginList,
  events: state.entities.events.chartList,
  loginTotalSize: state.entities.events.loginTotalSize,
  totalSize: state.entities.events.chartTotalSize,
  loading: state.entities.events.loading,
  threatDetected: state.entities.events.threatDetected,
  threatDetectedLoading: state.entities.events.threatDetectedLoading,
  topthreats: state.entities.events.topthreats,
  topthreatsLoading: state.entities.events.topthreatsLoading,
  topvulnerable: state.entities.events.topvulnerable,
  topvulnerableLoading: state.entities.events.topvulnerableLoading,
  threatsDetectedCount: state.entities.events.threatsDetectedCount,
})

const mapDispatchToProps = (dispatch) => ({
  loadThreatsDetected: (filters, selectedId, defaultCustomerId) =>
    dispatch(loadThreatsDetected(filters, selectedId, defaultCustomerId)),
  loadLoginPagesScannedEvents: (offset, selectedId, defaultCustomerId) =>
    dispatch(loadLoginPagesScannedEvents(offset, selectedId, defaultCustomerId)),
  loadTopThreats: (filters, selectedId, defaultCustomerId) =>
    dispatch(loadTopThreats(filters, selectedId, defaultCustomerId)),
  loadTopVulnerable: (filters, selectedId, defaultCustomerId) =>
    dispatch(loadTopVulnerable(filters, selectedId, defaultCustomerId)),
  setChartFilterInStore: (filters) => dispatch(setChartFilterInStore(filters)),
})

export default connect(mapStateToProps, mapDispatchToProps)(IncidentCharts)
