import React, { Component } from 'react'
import { connect } from 'react-redux'
import { DebounceInput } from 'react-debounce-input';

import Option from './option'
import './selectBox.scss'

import { ReactComponent as ChevronDown } from '../../../assets/chevronDown.svg'
import searchSvg from "../../../assets/magnifier.svg";
import closeSvg from "../../../assets/close.svg";

import { getSearchedExtensionUsers, clearSearchedUsers } from '../../../store/entities/customers';
import { isGlobalView } from '../../../utilities/config'
import Select from 'react-select';


class SelectBox extends Component {
  state = {
    currentSelected: { name: 'default', value: 'Select Option' },
    dropdownClasses: ['options-container', 'd-flex', 'flex-column'],
    chevClasses: ['ml-auto'],
    shouldShow: false,
    search: "",
    filteredOptions: []
  }

  constructor(props) {
    super(props)
    this.wrapperRef = React.createRef()
  }

  componentDidMount() {
    const { options, type, role, tokenRoles } = this.props
    const accessibleRoles = role.split(',')
    const shouldShow = accessibleRoles.filter((value) =>
      tokenRoles === value,
    )
    if (shouldShow.length > 0)
      document.addEventListener('mousedown', this.handleClickOutside, false)

    this.setState({
      currentSelected: { ...options[0], type },
      shouldShow: shouldShow.length ? true : false,
      filteredOptions: options
    })
  }

  componentDidUpdate(prevProps) {
    const { defaultOption, type } = this.props
    if (prevProps !== this.props) {
      if (this.props.customerChanged) {
        this.setState({
          currentSelected: { ...defaultOption, type },
          filteredOptions: this.props.options
        })
      } else {
        this.setState({
          filteredOptions: this.props.options
        })
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false)
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      const { dropdownClasses, chevClasses } = this.state
      if (dropdownClasses.indexOf('active') !== -1) {
        dropdownClasses.pop()
        chevClasses.pop()
        this.setState({ dropdownClasses, chevClasses, search: "", filteredOptions: this.props.options })
      }
    }
  }

  handleDropdownToggle = () => {
    const { dropdownClasses, chevClasses } = this.state
    if (dropdownClasses.indexOf('active') === -1) {
      dropdownClasses.push('active')
      chevClasses.push('toggle-chev')
    } else {
      dropdownClasses.pop()
      chevClasses.pop()
    }
    this.setState({ dropdownClasses, chevClasses })
    if (this.props.handleCustomerChange) {
      this.props.handleCustomerChange()
    }

  }

  handleOption = (e, type) => {
    const { dropdownClasses, chevClasses, currentSelected } = this.state
    currentSelected.value = e.currentTarget.children[0].id
    currentSelected.name = e.currentTarget.children[1].innerText
    currentSelected.type = type;
    let selectedOption = null;
    if (this.props.type === "org")
      selectedOption = this.props.options.find(item => item.key === e.currentTarget.children[0].id)
    else if (this.props.type === "users")
      selectedOption = this.props.searchedUsers.find(item => item.id === e.currentTarget.children[0].id)
    else
      selectedOption = this.props.options.find(item => item.id === e.currentTarget.children[0].id)
    // this.props.setCustomerDetails(selectedOption)
    selectedOption = { ...selectedOption, type: type }
    dropdownClasses.pop()
    chevClasses.pop()
    this.setState({ dropdownClasses, chevClasses, currentSelected, search: "" })
    this.props.onSelect(selectedOption)
  }

  handleSearch = (event) => {
    this.setState({ search: event.target.value });
    if (this.props.type !== "users") {
      let filtered = this.props.options.filter(option => 
        (
          this.props.type === 'org' ?
          option.displayName.toLowerCase().includes(event.target.value.toLowerCase()) :
          option.name.toLowerCase().includes(event.target.value.toLowerCase())
        )
      );
      this.setState({ filteredOptions: filtered });
    } else
      this.props.getSearchedExtensionUsers(this.props.selectedCustomer.id, event.target.value, this.props.selectedCustomer.key, isGlobalView(this.props.selectedCustomer.organizationName));
  }

  handleSearchClose = () => {
    this.setState({
      search: "",
      filteredOptions: this.props.options
    })

    if (this.props.type === "users")
      this.props.clearSearchedUsers();
  }

  render() {
    const { icon, iconClass, type, width, selectedCustomer, optionBoxWidth, showSearch } = this.props
    return (
      this.state.shouldShow && (
        <div ref={this.wrapperRef} className="select-box" style={{ width }}>
          <div style={{width: optionBoxWidth, display: "flex", flexDirection: "column" }} className={this.state.dropdownClasses.join(' ')}>
            {
              showSearch && <div
                className="d-flex flex-grow-1 align-items-center search-box-outline"
              >
                <img
                  className="search-box-magnifier"
                  src={searchSvg}
                  alt=""
                  srcSet=""
                />
                <DebounceInput
                  className="search-box"
                  minLength={2}
                  debounceTimeout={300}
                  value={this.state.search}
                  onChange={this.handleSearch} 
                  placeholder = 'Type here to search'
                  />
                <img
                  className="search-box-close"
                  src={closeSvg}
                  alt=""
                  srcSet=""
                  onClick={this.handleSearchClose}
                />
              </div>
            }

            {this.props.type === "org" && this.state.filteredOptions.map((option) => (
              <Option
                key={option.key}
                onOptionClick={this.handleOption}
                optionId={option.key}
                optionName={type}
                label={type === 'org' ? (option.displayName || option.organizationName) : option.name}
              />
            ))}
            {this.props.type === "users" && this.props.searchedUsers.map((option) => (
              <Option
                key={option.id}
                onOptionClick={this.handleOption}
                optionId={option.id}
                optionName={type}
                label={type === 'org' ? option.organizationName : option.name}
              />
            ))}
            {(this.props.type !== "org" && this.props.type !== "users") && this.state.filteredOptions.map((option) => (
              <Option
                key={option.id}
                onOptionClick={this.handleOption}
                optionId={option.id}
                optionName={type}
                label={option.name}
              />
            ))}
          </div>

          <div
            onClick={this.handleDropdownToggle}
            className="selected d-flex align-items-center"
          >
            <img
              src={icon}
              alt=""
              className='icon'
            />
            <div className={'text-truncate'}>
              <span className='initText'>
                {selectedCustomer && type === 'org' ? ( selectedCustomer.displayName ||selectedCustomer.organizationName ): this.props.selectedOption ? this.props.selectedOption.name : this.state.filteredOptions[0] ? this.state.filteredOptions[0].name :" " }
              </span>
            </div>
            <ChevronDown
              className={this.state.chevClasses.join(' ')}
              width="23px"
            />
          </div>
        </div>
      )
    )
  }
}

const mapStateToProps = (state) => ({
  tokenRoles: state.entities.user.roles,
  selectedCustomer: state.entities.customers.selected,
  searchedUsers: state.entities.customers.searchedUsers
})

const mapDispatchToProps = (dispatch) => ({
  getSearchedExtensionUsers: (customerId, searchTerm, key, isGlobalView) => dispatch(getSearchedExtensionUsers(customerId, searchTerm, key, isGlobalView)),
  clearSearchedUsers: () => dispatch(clearSearchedUsers()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectBox)
