import React, { Component } from 'react'
import Style from './AddOrganization.module.css'
import { setError, setCurrentCustomer, addWhitelistDomain } from '../store/entities/customers'
import { connect } from 'react-redux'
import ModalPopup from '../components/common/ModalPopup'

import BackIcon from '../assets/backButton.svg'

class AddWhitelistDomain extends Component {

    constructor() {
        super();
        this.state = {
            domain: ''
        };
    }

    componentDidUpdate(prevProps) {
    }

    handleModalClick = () => {
        this.props.history.push({ pathname: `/customers/${this.props.selectedCustomer.id}/settings`, state: { tab: 'Whitelist Management' } })  
    }

    handleChange = (event) => {
        const value = event.target.value
        this.setState({
            domain: value
        })
    }

    handleClick = () => {
            this.props.history.push({ pathname: `/customers/${this.props.selectedCustomer.id}/settings`, state: { tab: 'Whitelist Management' } })  
    }

    handleSubmit = () => {
        let pattern = new RegExp(/^(?!:\/\/)(?!www\.)(?!http:\/\/)(?!.*--)([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/); 
        /*
        1.Alphanumeric Characters and Hyphens:
            The domain name can contain letters (both uppercase and lowercase), numbers, and hyphens.
        2.No "www." at the Beginning:
            The domain name should not start with "www.".
        3.No "http://" at the Beginning:
            The domain name should not start with "http://" or "https://".
        4.At Least One Dot (.) in the Domain:
            There must be at least one dot (.) in the domain name, indicating the separation between the domain and the top-level domain (TLD).
        5.Top-Level Domain (TLD) Requirement:
            The domain should end with a valid top-level domain (e.g., ".com", ".net", ".org").
            The top-level domain must consist of at least two characters.
        6.Consecutive two hyphens are not allowed in domain name. 
        */
        if(this.state.domain === '') {
            let error = "Domain is required."
            this.props.setError(error)
        }
        else if(!pattern.test(this.state.domain)){
            let error = "Please enter a valid domain and do not include web protocols like http/https in the domain. "
            this.props.setError(error)
        }

        if( this.state.domain !== '' && pattern.test(this.state.domain)) {
            let body = {
                "domain": this.state.domain
            }
            this.props.addWhitelistDomain(this.props.selectedCustomer.id, body)
        }
        
    }

    render() {
        return (
            <div className={'px-5 mt-4'}>
                <div className={`${Style.headerBg} px-5 d-flex align-items-center justify-content-between`}>
                    <div onClick={this.handleClick} style={{ cursor: 'pointer' }}>
                        <img style={{ height: '1.5vw' }} src={BackIcon} alt={'back-icon'} />
                        Return to Allowed Domains
                    </div>
                </div>
                <div className={`${Style.detailsContainer} w-50 mt-4 py-5 px-5`}>
                    <div className={`d-flex flex-column`}>
                        <input name='Domain' placeholder={'Domain'} className={`${Style.inputStyle} focus-none mt-3`} onChange={this.handleChange} />
                    </div>
                    <div className={'mt-4'}>
                        <button onClick={this.handleSubmit} className={`${Style.submitButton} px-4 focus-none`}>Add Domain</button>
                        <button onClick={this.handleClick} className={`${Style.cancelButton} px-4 ml-2 focus-none`}>Cancel</button>
                    </div>
                </div>
                {this.props.statusCode && <ModalPopup handleClick={this.handleModalClick} statusCode={this.props.statusCode} msg={this.props.userMsg}/> }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    selectedCustomer: state.entities.customers.selected,
    userRole: state.entities.user.roles,
    userMsg: state.entities.customers.userMsg,
    statusCode: state.entities.customers.statusCode,
})

const mapDispatchToProps = (dispatch) => ({
    addWhitelistDomain: (customerId, body) => dispatch(addWhitelistDomain(customerId, body)),
    setError: (error) => dispatch(setError(error)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddWhitelistDomain)