import { getUTCDate } from '../../utilities/datetime'
import { eventTypes } from '../../utilities/eventTypes'

export const eventsInitialState = {
  threatView: {
    list: ['chart', 'list', 'grid'],
    currentView: 'list',
    default: 'list',
  },

  threat: {
    filters: {
      type: [eventTypes.incident, eventTypes.reported_phish, eventTypes.google_form_phish],
      offset: 0,
      cleared: 0,
      pageSize: 21,
      startTime: getUTCDate(30),
      endTime: getUTCDate(0),
      order: 'desc',
    },
  },

  chart: {
    filters: {
      type: [eventTypes.incident],
      offset: 0,
      cleared: 0,
      startTime: getUTCDate(30),
      endTime: getUTCDate(0),
      order: 'desc',
    },
  },

  list: [],
  totalSize: 0,
  loading: false,
  selected: null,
  userMsg: null,
  statusCode: null,
  isEventsLoadOnceIncidents: true,
  threatDetected: [],
  topthreats: [],
  topvulnerable: [],
  switchToChart : false,
  switchToChartOnLogin : true
}
