import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip'
import './tooltip.scss'

export class Tooltip extends Component {
  render() {
    return (
      <>
        <ReactTooltip id={this.props.id} place="top" effect="solid" type='info' className='_tooltip'>
          <span style={{fontSize: '16px'}}>{this.props.data}</span>
        </ReactTooltip>
      </>
    )
  }
}

export default Tooltip
